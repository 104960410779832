import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import getImageData from '$utils/getImageData';
import { WrapMax } from '$components/Wraps';
import FadeInSection from '$components/FadeInSection';
import Article from './Article';
import Figure from './Figure';
import Content from './Content';
import { BlockImageTextProps } from './types';

const StyledWrap = styled(WrapMax)`
  &:last-child {
    margin-bottom: 4em;
  }
`;

const BlockImageText: React.FC<BlockImageTextProps> = ({
  imagePosition,
  image,
  heading,
  content,
  link,
  slug,
  title,
  shortDescription,
  id,
}) => {
  const rtl = imagePosition !== 'Left';

  return (
    <StyledWrap>
      <FadeInSection>
        <Article rtl={rtl}>
          {image && (
            <Figure rtl={rtl}>
              <GatsbyImage image={getImageData(image.imageFile)} alt={image.alternativeText} />
            </Figure>
          )}
          <Content
            heading={heading}
            content={content}
            link={link}
            imagePosition={imagePosition}
            image={image}
            slug={slug}
            title={title}
            shortDescription={shortDescription}
            id={id}
          />
        </Article>
      </FadeInSection>
    </StyledWrap>
  );
};

export default BlockImageText;

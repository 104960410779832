import React from 'react';
import styled from 'styled-components';
import { FigureProps } from './types';

const StyledFigure = styled.figure<FigureProps>`
  position: relative;
  margin: 0;
  ${(props) =>
    props.rtl &&
    `
  @media screen and (min-width: 1024px) {
    order: 2;
  }`}
`;

const Figure: React.FC<FigureProps> = ({ children, rtl }) => <StyledFigure rtl={rtl}>{children}</StyledFigure>;

export default Figure;

import React from 'react';
import styled from 'styled-components';
import scalingSize from '$utils/scalingSize';
import Button from '$components/Button';
import { BlockImageTextProps } from './types';
import Markdown from '$components/Markdown';

const Title = styled.h2`
  color: var(--color-slate);
  margin-top: 0;
  ${scalingSize('font-size', 24, 48)}
  line-height: 1.1;
  margin-bottom: 0.571428571em;
`;

const CtaList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding: 0.25em 0;
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1em;
  padding-bottom: 1em;
`;

const Content: React.FC<BlockImageTextProps> = ({ heading, content, link }) => (
  <StyledContent>
    <Title>{heading}</Title>
    <Markdown content={content} />
    {link && !Array.isArray(link) && (
      <Button to={link.URL} theme="ghost" size="small" showArrow>
        {link.Title}
      </Button>
    )}
    {link && Array.isArray(link) && (
      <CtaList>
        {link.map((cta) => (
          <li key={`button_${cta.URL}`}>
            <Button to={cta.URL} theme="ghost" size="small" showArrow>
              {cta.Title}
            </Button>
          </li>
        ))}
      </CtaList>
    )}
  </StyledContent>
);

export default Content;

import React from 'react';
import styled from 'styled-components';
import dynamicMargin from '$utils/dynamicMargin';
import { ArticleProps } from './types';

const StyledArticle = styled.article<ArticleProps>`
  display: grid;
  grid-gap: 2em 5vw;
  align-items: center;
  ${dynamicMargin};

  @media screen and (min-width: 1680px) {
    grid-gap: 2em 5.25em;
  }

  ${(props) =>
    props.rtl
      ? `
  @media screen and (min-width: 1024px) {
    grid-template-columns: 4fr 6fr;
  }`
      : `
  @media screen and (min-width: 1024px) {
    grid-template-columns: 6fr 4fr;
  }
  `}

  p {
    color: var(--color-body-text);
    margin-top: 0;
  }
`;

const Article: React.FC<ArticleProps> = ({ children, rtl }) => <StyledArticle rtl={rtl}>{children}</StyledArticle>;

export default Article;
